<template>
  <Analysis type="forecast"></Analysis>
</template>

<script>
import Analysis from './Analysis.vue';
export default {
  name: 'commentary_forecast_cycle',
  // props: ['props'],
  data: function () {
    return {
    }
  },
  components: {
    Analysis
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  methods: {
    refreshData() {
    },
  },
  watch: {
  }
}
</script>
